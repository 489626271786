import React from "react";

export default function Welcome() {
  return (
    <div className="welcome">
      <h1>
        Welcome to my<span>Portfolio!</span>
      </h1>
    </div>
  );
}
